<template>
  <fki-sub-nav
    is-active
    color="page.color"
    :items="items"
  />
  <fki-container
    fixed
    top="196px"
    left="0"
    width="100%"
  >
    <fki-footer is-active>
      <fki-footer-nav is-active />
    </fki-footer>
  </fki-container>
</template>

<script>
// @ is an alias to /src
import Api from '@/services/api'
import { FKISubNav, FKIFooterNav } from '@/components/FKINav'
import { FKIContainer } from '@/components/FKIContainer'
import { FKIFooter } from '@/components/FKIApp'

export default {
  name: 'Contact',
  components: {
    'fki-sub-nav': FKISubNav,
    'fki-container': FKIContainer,
    'fki-footer': FKIFooter,
    'fki-footer-nav': FKIFooterNav
  },
  props: {
  },
  emits: ['isLoading', 'setBackgrounds'],
  data () {
    return {
      currentFacility: null,
      page: { color: 'orange', body: null, slides: [] },
      items: []
    }
  },
  watch: {
    $route: 'checkParams'
  },
  async created () {
    this.checkParams()
  },
  methods: {
    checkParams () {
      if (this.$route.path === '/contact') {
        this.$router
          .push({
            path: '/contact/fotokem'
          })
          .catch(() => {})
      }
    },
    async getContacts () {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)
        const { data: result } = await Api().get('/contacts')
        this.items = []
        for (const key in result) {
          if (Object.hasOwnProperty.call(result, key)) {
            this.items.push({
              slug: 'contact/' + result[key].slug,
              color: this.page.color,
              name: result[key].name
            })
          }
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    },
    async getPage () {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)
        const { data: result } = await Api().get('/page/s/contact')
        this.page = result
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    }
  }
}
</script>
